import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from './logo'
import { SearchIcon, Facebook, Instagram, LinkedIn } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    search: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 20);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleSearch = () => {
    this.setState({ search: !this.state.search })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--menu-open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/buy/' className='header__search-icon'>
              <SearchIcon color={`#EFEEEA`} />
            </Link>
            <nav className='header__nav  header__nav--left'>
              <ul>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/lease/' {...props}>Lease</Link></li>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
                <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
              </ul>
            </nav>
            <Link to='/' title='Wills Property' className='header__logo' {...props}>
              <Logo color={'#38291D'} />
            </Link>
            <nav className='header__nav header__nav--right'>
              <ul>
                <li>
                  <Link to='/our-story/' {...props}>About</Link>
                  <ul>
                    <li><Link to='/our-story/' {...props}>Our Story</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to='/tenant-information/' {...props}>Resources</Link>
                  <ul>
                    <li><Link to='/tenant-information/' {...props}>Tenancy Application</Link></li>
                    <li><Link to='/repair-request/' {...props}>Request a Repair</Link></li>
                    <li><Link to='/vacate-notice/' {...props}>Vacate a Property</Link></li>
                    <li><Link to='/property-maintenance/' {...props}>Property Maintenance</Link></li>
                    <li><Link to='/tenant-information/' {...props}>Tenant Info</Link></li>
                    <li><Link to='/useful-links/' {...props}>Useful Links</Link></li>
                    <li><Link to='https://client.propertytree.com/login' {...props}>Owners Portal</Link></li>
                  </ul>
                </li>
                <li><Link to='/news/' {...props}>News</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <div className='off-canvas__col'>
                <p>Property</p>
                <ul>
                  <li><Link to='/buy/' {...props}>Buy</Link></li>
                  <li><Link to='/lease/' {...props}>Lease</Link></li>
                  <li><Link to='/sold/' {...props}>Sold</Link></li>
                  <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                </ul>
              </div>
              <div className='off-canvas__col'>
                <p>Our Family</p>
                <ul>
                  <li><Link to='/our-story/' {...props}>Our Story</Link></li>
                  <li><Link to='/news/' {...props}>News</Link></li>
                  <li><Link to='/contact/' {...props}>Contact</Link></li>
                </ul>
              </div>
              <div className='off-canvas__col center'>
                <p>Resources</p>
                <ul>
                  <li><Link to='/tenant-information/' {...props}>Tenancy Application</Link></li>
                  <li><Link to='/repair-request/' {...props}>Request a Repair</Link></li>
                  <li><Link to='/vacate-notice/' {...props}>Vacate a Property</Link></li>
                  <li><Link to='/property-maintenance/' {...props}>Property Maintenance</Link></li>
                  <li><Link to='/tenant-information/' {...props}>Tenant Info</Link></li>
                  <li><Link to='/useful-links/' {...props}>Useful Links</Link></li>
                  <li><Link to='https://client.propertytree.com/login' {...props}>Owners Portal</Link></li>
                </ul>
              </div>
            </nav>
            <div className='off-canvas__bottom'>
              <ul className='off-canvas__social'>
                <li><Link to='https://www.instagram.com/willspropertyau/?hl=en'><Instagram color={'#EFEEEA'} /></Link></li>
                <li><Link to='https://www.linkedin.com/company/wills-property/'><LinkedIn color={'#EFEEEA'} /></Link></li>
                <li><Link to='https://www.facebook.com/willsproperty.com.au/'><Facebook color={'#EFEEEA'} /></Link></li>
              </ul>
              <ul className='off-canvas__links'>
                <li><Link to='/terms-conditions/'>Terms &amp; Conditions</Link></li>
                <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
